import React,{useState} from 'react'
import './home.css'
import video0 from './title.mp4'
import video1 from './home.mp4'
import video2 from './journey.mp4'
import poster from './poster.png'
import { useEffect } from 'react'

const Home = ({utente}) => {
  const [c,setC]=useState(9)
  const [d,setD]=useState(9)
  const cities = [
    { 
      rank: 20, 
      city: "Amsterdam", 
      country: "Netherlands", 
      latitude: 52.3676, 
      longitude: 4.9041, 
      image: "https://images.pexels.com/photos/1790573/pexels-photo-1790573.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    { 
      rank: 19, 
      city: "Los Angeles", 
      country: "USA", 
      latitude: 34.0522, 
      longitude: -118.2437, 
      image: "https://images.pexels.com/photos/3166785/pexels-photo-3166785.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    { 
      rank: 18, 
      city: "Vienna", 
      country: "Austria", 
      latitude: 48.2082, 
      longitude: 16.3738, 
      image: "https://images.pexels.com/photos/2351425/pexels-photo-2351425.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
    },
    { 
      rank: 17, 
      city: "Berlin", 
      country: "Germany", 
      latitude: 52.5200, 
      longitude: 13.4050, 
      image: "https://images.pexels.com/photos/65567/pexels-photo-65567.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" 
    },
    {
      rank: 16,
      city: "Milan",
      country: "Italy",
      latitude: 45.4642,
      longitude: 9.1900,
      image: "https://images.pexels.com/photos/8243237/pexels-photo-8243237.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 15,
      city: "Prague",
      country: "Czech Republic",
      latitude: 50.0755,
      longitude: 14.4378,
      image: "https://images.pexels.com/photos/126292/pexels-photo-126292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 14,
      city: "Hong Kong",
      country: "Hong Kong",
      latitude: 22.3193,
      longitude: 114.1694,
      image: "https://images.pexels.com/photos/1757231/pexels-photo-1757231.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 13,
      city: "Sydney",
      country: "Australia",
      latitude: -33.8688,
      longitude: 151.2093,
      image: "https://images.pexels.com/photos/2193300/pexels-photo-2193300.jpeg?auto=compress&cs=tinysrgb&w=700"
    },
    {
      rank: 12,
      city: "Moscow",
      country: "Russia",
      latitude: 55.7558,
      longitude: 37.6173,
      image: "https://images.pexels.com/photos/753339/pexels-photo-753339.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 11,
      city: "Seoul",
      country: "South Korea",
      latitude: 37.5665,
      longitude: 126.9780,
      image: "https://images.pexels.com/photos/237211/pexels-photo-237211.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    { 
      rank: 10, 
      city: "Istanbul", 
      country: "Turkey", 
      latitude: 41.0082, 
      longitude: 28.9784, 
      image: "https://images.pexels.com/photos/3889704/pexels-photo-3889704.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    { 
      rank: 9, 
      city: "Barcelona", 
      country: "Spain", 
      latitude: 41.3851, 
      longitude: 2.1734, 
      image: "https://images.pexels.com/photos/1874675/pexels-photo-1874675.jpeg?auto=compress&cs=tinysrgb&w=700"
    },
    { 
      rank: 8, 
      city: "Singapore", 
      country: "Singapore", 
      latitude: 1.3521, 
      longitude: 103.8198, 
      image: "https://images.pexels.com/photos/326699/pexels-photo-326699.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    { 
      rank: 7, 
      city: "Bangkok", 
      country: "Thailand", 
      latitude: 13.7563, 
      longitude: 100.5018, 
      image: "https://images.pexels.com/photos/1031659/pexels-photo-1031659.jpeg?auto=compress&cs=tinysrgb&w=600" 
    },
    {
      rank: 6,
      city: "Dubai",
      country: "Arab Emirates",
      latitude: 25.276987,
      longitude: 55.296249,
      image: "https://images.pexels.com/photos/1467300/pexels-photo-1467300.jpeg?auto=compress&cs=tinysrgb&w=600"
    },
    {
      rank: 5,
      city: "London",
      country: "UK",
      latitude: 51.507341,
      longitude: -0.127497,
      image: "https://images.pexels.com/photos/635608/pexels-photo-635608.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 4,
      city: "Tokyo",
      country: "Japan",
      latitude: 35.680578,
      longitude: 139.768496,
      image: "https://images.pexels.com/photos/2506923/pexels-photo-2506923.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 3,
      city: "Rome",
      country: "Italy",
      latitude: 41.896562,
      longitude: 12.482170,
      image: "https://images.pexels.com/photos/2422251/pexels-photo-2422251.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 2,
      city: "Paris",
      country: "France",
      latitude: 48.857450,
      longitude: 2.351338,
      image: "https://images.pexels.com/photos/3105066/pexels-photo-3105066.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 1,
      city: "New York",
      country: "USA",
      latitude: 40.712376,
      longitude: -74.006040,
      image: "https://images.pexels.com/photos/1389339/pexels-photo-1389339.jpeg?auto=compress&cs=tinysrgb&w=800"
    }
  ];
  const attractions = [
    {
      rank: 20,
      name: "Acropolis of Athens",
      city: "Atene",
      country: "Grecia",
      latitude: 37.9715,
      longitude: 23.7257,
      image: "https://images.pexels.com/photos/15186555/pexels-photo-15186555/free-photo-of-citta-persone-punto-di-riferimento-edificio.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 19,
      name: "Empire State Building",
      city: "New York",
      country: "USA",
      latitude: 40.748817,
      longitude: -73.985428,
      image: "https://images.pexels.com/photos/2404843/pexels-photo-2404843.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 18,
      name: "Sagrada Familia",
      city: "Barcellona",
      country: "Spagna",
      latitude: 41.4036,
      longitude: 2.1744,
      image: "https://images.pexels.com/photos/819764/pexels-photo-819764.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 17,
      name: "Christ the Redeemer",
      city: "Rio de Janeiro",
      country: "Brasile",
      latitude: -22.9519,
      longitude: -43.2105,
      image: "https://images.pexels.com/photos/2868240/pexels-photo-2868240.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 16,
      name: "Sydney Opera House",
      city: "Sydney",
      country: "Australia",
      latitude: -33.8568,
      longitude: 151.2153,
      image: "https://images.pexels.com/photos/1878293/pexels-photo-1878293.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 15,
      name: "Louvre Museum",
      city: "Parigi",
      country: "Francia",
      latitude: 48.8606,
      longitude: 2.3376,
      image: "https://images.pexels.com/photos/11783055/pexels-photo-11783055.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 14,
      name: "Petra",
      city: "Ma'an",
      country: "Giordania",
      latitude: 30.3285,
      longitude: 35.4444,
      image: "https://images.pexels.com/photos/4388167/pexels-photo-4388167.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 13,
      name: "Grand Canyon",
      city: "Arizona",
      country: "USA",
      latitude: 36.1070,
      longitude: -112.1130,
      image: "https://images.pexels.com/photos/2379864/pexels-photo-2379864.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 12,
      name: "Mont Saint-Michel",
      city: "Normandia",
      country: "Francia",
      latitude: 48.6361,
      longitude: -1.5115,
      image: "https://images.pexels.com/photos/4245816/pexels-photo-4245816.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 11,
      name: "St. Peter's Basilica",
      city: "Città del Vaticano",
      country: "Vaticano",
      latitude: 41.9022,
      longitude: 12.4539,
      image: "https://images.pexels.com/photos/6188084/pexels-photo-6188084.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 10,
      name: "Machu Picchu",
      city: "Cusco",
      country: "Perù",
      latitude: -13.1631,
      longitude: -72.5450,
      image: "https://images.pexels.com/photos/90597/pexels-photo-90597.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 9,
      name: "Taj Mahal",
      city: "Agra",
      country: "India",
      latitude: 27.1751,
      longitude: 78.0421,
      image: "https://images.pexels.com/photos/5458393/pexels-photo-5458393.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 8,
      name: "Golden Gate Bridge",
      city: "San Francisco",
      country: "USA",
      latitude: 37.8199,
      longitude: -122.4783,
      image: "https://images.pexels.com/photos/1006964/pexels-photo-1006964.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 7,
      name: "Colosseum",
      city: "Roma",
      country: "Italia",
      latitude: 41.8902,
      longitude: 12.4922,
      image: "https://images.pexels.com/photos/2225439/pexels-photo-2225439.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 6,
      name: "Statue of Liberty",
      city: "New York",
      country: "USA",
      latitude: 40.6892,
      longitude: -74.0445,
      image: "https://images.pexels.com/photos/887848/pexels-photo-887848.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 5,
      name: "Pyramids of Giza",
      city: "Giza",
      country: "Egitto",
      latitude: 29.9792,
      longitude: 31.1342,
      image: "https://images.pexels.com/photos/3772630/pexels-photo-3772630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 4,
      name: "Forbidden City",
      city: "Pechino",
      country: "Cina",
      latitude: 39.9163,
      longitude: 116.3972,
      image: "https://images.pexels.com/photos/189833/pexels-photo-189833.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 3,
      name: "Palace of Versailles",
      city: "Versailles",
      country: "Francia",
      latitude: 48.8049,
      longitude: 2.1204,
      image: "https://images.pexels.com/photos/13692202/pexels-photo-13692202.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 2,
      name: "Great Wall of China",
      city: "Pechino",
      country: "Cina",
      latitude: 40.4319,
      longitude: 116.5704,
      image: "https://images.pexels.com/photos/2304791/pexels-photo-2304791.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      rank: 1,
      name: "Yellowstone National Park",
      city: "Wyoming",
      country: "USA",
      latitude: 44.4280,
      longitude: -110.5885,
      image: "https://images.pexels.com/photos/417074/pexels-photo-417074.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    }
  ];
  useEffect(()=>{
    const video1 = document.getElementById('video-0-1');
    const video2 = document.getElementById('video-0-2');
    if(video1 && video2){
      function syncVideos(source, target) {
        if (Math.abs(source.currentTime - target.currentTime) > 0.2) {
          target.currentTime = source.currentTime;
        }
      }
      video1.addEventListener('timeupdate', () => syncVideos(video1, video2));
      video2.addEventListener('timeupdate', () => syncVideos(video2, video1));
      video1.addEventListener('canplay', () => {
        if (!video2.paused && video2.readyState >= 3) {
          video1.play();
          video2.play();
        }
      });
      video2.addEventListener('canplay', () => {
        if (!video1.paused && video1.readyState >= 3) {
          video1.play();
          video2.play();
        }
      });
      video1.addEventListener('ended', () => {
        video1.currentTime = 0;
        video1.play();
        video2.currentTime = 0;
        video2.play();
      });
    }
  },[])
  const [isPlaying, setIsPlaying] = useState(false);
  const handlePlay = () => {
    setIsPlaying(true);
  };
  const handlePause = () => {
    setIsPlaying(false);
  };
  const [isPlaying2, setIsPlaying2] = useState(false);
  const handlePlay2 = () => {
    setIsPlaying2(true);
  };
  const handlePause2 = () => {
    setIsPlaying2(false);
  };
  return (
    <div className='home'>
      <div className='home-title'>
        <video playsinline id='video-0-1' autoPlay muted src={video0} poster={poster}></video>
        <video playsinline id='video-0-2' autoPlay muted src={video0} poster={poster}></video>
        <div className='home-title-content'>
          <small>Let's use the</small>
          <h1>Best Free Online Travel Guide</h1>
          <div className='home-title-content-button'>
            <a href="/explore" id='title-content-button1'>Get Started</a>
            {/*<a href="/add_home_page" id='title-content-button2'>Add to Home Page</a>*/}
          </div>
        </div>
      </div>
      <div className='home-content'>
        <div className='home-content-types'>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
              <path d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8m-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7"/>
            </svg>
            <p>Art</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1q.01.775.056 1.469c.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.5.5 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667q.045-.694.056-1.469z"/>
            </svg>
            <p>Castles</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"/>
            </svg>
            <p>Dig Sites</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0m4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5z"/>
            </svg>
            <p>Main Attractions</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04M4.705 11.912a1.2 1.2 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.4 3.4 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3 3 0 0 0 .126-.75zm1.44.026c.12-.04.277-.1.458-.183a5.1 5.1 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005zm3.582-3.043.002.001h-.002z"/>
            </svg>
            <p>Museums</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"/>
            </svg>
            <p>Religious Places</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z"/>
            </svg>
            <p>Ruins</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3m4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3M5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3"/>
              <path d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8m-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7"/>
            </svg>
            <p>Art</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1q.01.775.056 1.469c.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.5.5 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667q.045-.694.056-1.469z"/>
            </svg>
            <p>Castles</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="m8 0 6.61 3h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.38l.5 2a.498.498 0 0 1-.485.62H.5a.498.498 0 0 1-.485-.62l.5-2A.5.5 0 0 1 1 13V6H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 3h.89zM3.777 3h8.447L8 1zM2 6v7h1V6zm2 0v7h2.5V6zm3.5 0v7h1V6zm2 0v7H12V6zM13 6v7h1V6zm2-1V4H1v1zm-.39 9H1.39l-.25 1h13.72z"/>
            </svg>
            <p>Dig Sites</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M7 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0m4.225 4.053a.5.5 0 0 0-.577.093l-3.71 4.71-2.66-2.772a.5.5 0 0 0-.63.062L.002 13v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4.5z"/>
            </svg>
            <p>Main Attractions</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M15.825.12a.5.5 0 0 1 .132.584c-1.53 3.43-4.743 8.17-7.095 10.64a6.1 6.1 0 0 1-2.373 1.534c-.018.227-.06.538-.16.868-.201.659-.667 1.479-1.708 1.74a8.1 8.1 0 0 1-3.078.132 4 4 0 0 1-.562-.135 1.4 1.4 0 0 1-.466-.247.7.7 0 0 1-.204-.288.62.62 0 0 1 .004-.443c.095-.245.316-.38.461-.452.394-.197.625-.453.867-.826.095-.144.184-.297.287-.472l.117-.198c.151-.255.326-.54.546-.848.528-.739 1.201-.925 1.746-.896q.19.012.348.048c.062-.172.142-.38.238-.608.261-.619.658-1.419 1.187-2.069 2.176-2.67 6.18-6.206 9.117-8.104a.5.5 0 0 1 .596.04M4.705 11.912a1.2 1.2 0 0 0-.419-.1c-.246-.013-.573.05-.879.479-.197.275-.355.532-.5.777l-.105.177c-.106.181-.213.362-.32.528a3.4 3.4 0 0 1-.76.861c.69.112 1.736.111 2.657-.12.559-.139.843-.569.993-1.06a3 3 0 0 0 .126-.75zm1.44.026c.12-.04.277-.1.458-.183a5.1 5.1 0 0 0 1.535-1.1c1.9-1.996 4.412-5.57 6.052-8.631-2.59 1.927-5.566 4.66-7.302 6.792-.442.543-.795 1.243-1.042 1.826-.121.288-.214.54-.275.72v.001l.575.575zm-4.973 3.04.007-.005zm3.582-3.043.002.001h-.002z"/>
            </svg>
            <p>Museums</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"/>
            </svg>
            <p>Religious Places</p>
          </div>
          <div className='home-content-types-item'>
            <svg viewBox="0 0 16 16">
              <path d="M0 .5A.5.5 0 0 1 .5 0h15a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H14v2h1.5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5H2v-2H.5a.5.5 0 0 1-.5-.5v-3A.5.5 0 0 1 .5 6H2V4H.5a.5.5 0 0 1-.5-.5zM3 4v2h4.5V4zm5.5 0v2H13V4zM3 10v2h4.5v-2zm5.5 0v2H13v-2zM1 1v2h3.5V1zm4.5 0v2h5V1zm6 0v2H15V1zM1 7v2h3.5V7zm4.5 0v2h5V7zm6 0v2H15V7zM1 13v2h3.5v-2zm4.5 0v2h5v-2zm6 0v2H15v-2z"/>
            </svg>
            <p>Ruins</p>
          </div>
        </div>
        <div className='home-content-subtitle'>
          <h3>Cronology</h3>
          <svg viewBox="0 0 16 16" onClick={()=>{
            if(window.confirm("Do you want delete your cronology?")){
              localStorage.removeItem("cronology")
              window.location.reload()
            }
          }}>
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
        </div>
        <div className='home-content-popular'>
          {localStorage.getItem("cronology")?JSON.parse(localStorage.getItem("cronology")).reverse().map((item,key)=>{
            return(
              <a href={"/explore?lat="+item.lat+"&&lon="+item.lon}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+item.img+")"}}>
                  <div className='home-content-popular-item-content'>
                    <div className='home-content-popular-item-content-info'>
                      <svg viewBox="0 0 16 16">
                        <path d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
                        <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
                        <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
                      </svg>
                      <big>{' '+new Date(item.data).getDate()}/{new Date(item.data).getMonth()}/{new Date(item.data).getFullYear()} {new Date(item.data).getHours()}:{new Date(item.data).getMinutes()}</big>
                      {utente&&utente.cronology&&utente.cronology.find(e=>item.nome===e.nome)&&<svg style={{fill:"#50C878"}} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0"/>
                        <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
                      </svg>}
                    </div>
                    <p>{item.nome}</p>
                  </div>
                </div>
              </a>
            )
          }):<img src='https://img.freepik.com/free-vector/hand-drawn-no-data-illustration_23-2150584286.jpg?t=st=1730017467~exp=1730021067~hmac=ccb128c09d6bcc8c7a01a6287b8f98c28825473040cb70acdc1ecc50d4593a30&w=740'/>}
        </div>
        {/*<div id='home-content-separator1' className='home-content-separator'><div></div><div>+150 countries</div></div>*/}
        <div className='home-content-subtitle'>
          <h3>Popular Destinations</h3>
          <small onClick={()=>setC(cities.length)}>See All</small>
        </div>
        <div className='home-content-popular'>
          {cities.slice(0,c).map(city=>{
            return(
              <a href={"/explore?lat="+city.latitude+"&&lon="+city.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+city.image+")"}}>
                  <div className='home-content-popular-item-content'>
                    <div className='home-content-popular-item-content-info'>
                      <small>
                        {city.rank}
                        <svg viewBox="0 0 16 16">
                          <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                          <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                        </svg>
                      </small>
                      <big>{' '+city.country}</big>
                    </div>
                    <p>{city.city}</p>
                  </div>
                </div>
              </a>
            )
          })}
        </div>
        {/*<div id='home-content-separator2' className='home-content-separator'><div>100% online</div></div>*/}
        <div className='home-content-subtitle'>
          <h3>Popular sites</h3>
          <small onClick={()=>setD(attractions.length)}>See All</small>
        </div>
        <div className='home-content-popular'>
          {attractions.slice(0,d).map(attraction=>{
            return(
              <a href={"/explore?lat="+attraction.latitude+"&&lon="+attraction.longitude}>
                <div className='home-content-popular-item' style={{backgroundImage:"url("+attraction.image+")"}}>
                  <div className='home-content-popular-item-content'>
                    <div className='home-content-popular-item-content-info'>
                      <small>
                        {attraction.rank}
                        <svg viewBox="0 0 16 16">
                          <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
                          <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
                        </svg>
                      </small>
                      <big>{' '+attraction.city}, {attraction.country}</big>
                    </div>
                    <p>{attraction.name}</p>
                  </div>
                </div>
              </a>
            )
          })}
        </div>
        <div className='home-content-subtitle'>
          <h3>Videos</h3>
        </div>
        <div className='home-content-video'>
          <div className='home-content-video-item'>
            {!isPlaying&&<div className='home-content-video-item-text'>
              <p>Video Presentation</p>
              <small>Becomes part of our world</small>
            </div>}
            <video id='video1' controls src={video1} onPlay={handlePlay} onPause={handlePause}></video>
          </div>
          <div className='home-content-video-item'>
            {!isPlaying2&&<div className='home-content-video-item-text'>
              <p>Video Tutorial</p>
              <small>Little tutorial to visit our big world</small>
            </div>}
            <video id='video2' controls src={video2} onPlay={handlePlay2} onPause={handlePause2}></video>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home