import React, { useEffect, useState } from "react";
import {BrowserRouter,Route,Routes} from 'react-router-dom';
import axios from "axios"
import Home from "./components/home/Home"
import Explore from "./components/explore/Explore";
import Navbar from "./components/navbar/Navbar";
import About from "./components/about/About";
import Contacts from "./components/contacts/Contacts";
import Login from "./components/login/Login";
import Utente from "./components/utente/Utente";
import Footer from "./components/footer/Footer";
import Install from "./components/installation/Install";
import PageError from "./components/pageError/PageError";

function App(){
  const [utente,setUtente]=useState()
  const serverURL="https://cultura-server.onrender.com/"
  //const serverURL="http://localhost:3001/"
  const stayLogin=()=>{
    if(document.cookie.split(";").find(i=>i.includes("email"))&&document.cookie.split(";").find(i=>i.includes("password"))){
      axios.put(serverURL+"login",{
        email:document.cookie.split(";").find(i=>i.includes("email")).trim().split("=")[1],
        password:document.cookie.split(";").find(i=>i.includes("password")).trim().split("=")[1],
      },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          setUtente(e.data)
          let date=new Date()
          date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000))
          const expires=date.toUTCString()
          document.cookie="email="+document.cookie.split(";").find(i=>i.includes("email")).trim().split("=")[1]+"; expires="+expires
          document.cookie="password="+document.cookie.split(";").find(i=>i.includes("password")).trim().split("=")[1]+"; expires="+expires
        }else{
          alert("Error: You are not logged in")
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          setUtente(203)
        }
      })
    }
  }
  const handleError = (error) => {
    alert("Error: "+JSON.stringify(error))
  };
  useEffect(()=>{
    stayLogin()
    window.addEventListener('error', handleError);
    window.addEventListener('unhandledrejection', handleError);
  },[])
  return (
    <BrowserRouter>
      <Navbar utente={utente}/>
      <Routes>
      <Route path='/' element={<Home serverURL={serverURL} utente={utente}/>}/>
        <Route path='/who' element={<About/>}/>
        <Route path='/contacts' element={<Contacts serverURL={serverURL}/>}/>
        <Route path='/explore' element={<Explore serverURL={serverURL} utente={utente}/>}/>
        <Route path='/login' element={<Login serverURL={serverURL}/>}/>
        <Route path='/profile' element={<Utente serverURL={serverURL} utente={utente}/>}/>
        <Route path='/add_home_page' element={<Install serverURL={serverURL} utente={utente}/>}/>
        <Route path='/*' element={<PageError />}/>
      </Routes>
      {!window.location.pathname.includes("explore")&&<Footer/>}
    </BrowserRouter>
  );
}

export default App;
