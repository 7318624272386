import React,{useState,useEffect} from 'react'
import "./utente.css"
import axios from "axios"
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import worldGeoJSON from './custom.geo.json';

const Utente = ({serverURL,utente}) => {
  document.title="Profile | Journey"
  const delet =()=>{
    axios.put(serverURL+"delete",{
      email:utente.email,
      password:utente.password,
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          window.location.href="/"
        }else{
          alert("Error: "+e.data)
        }
    })
  }
  useEffect(()=>{
    const timer = setTimeout(()=>{
      if(!document.cookie.split(";").find(i=>i.includes("email"))&&!document.cookie.split(";").find(i=>i.includes("password"))){
        window.location.href="/login"
      }else{
        if(utente===203){
          document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
      }
    },1000);
    return ()=>clearTimeout(timer);
  },[]);
  const [suggest,setSuggest]=useState()
  function suggestCities(inputText){
    setSuggest()
    fetch(`https://nominatim.openstreetmap.org/search?city=${inputText}&format=json`)
      .then(response => response.json())
      .then(data =>{
        setSuggest(data)
      })
      .catch(error => {
        setSuggest("<div className='error'>Errore durante il recupero dei suggerimenti</div>");
      });
  }
  const [addMemories,setAddMemories]=useState(false)
  function sendBoard(){
    axios.put(serverURL+"board",{
      email:utente.email,
      password:utente.password,
      citta:document.getElementById("cityInput").value,
      dataBoard:document.getElementById("dateInput").value,
      text:document.getElementById("textInput").value,
      data:new Date()
    },{headers:{'Content-Type':'multipart/form-data'}}).then((e)=>{
        if(e.status===200){
          window.location.reload()
        }else{
          alert("Error: "+e.data)
        }
    })
  }
  const ShowVisitedCountries=()=>{
    const [visitedCountries, setVisitedCountries] = useState([]);
    useEffect(() => {
      const fetchVisitedCountries = async () => {
        if(utente && utente.cronology){
          const countries = await Promise.all(
            utente.cronology.map(async (item) => {
              if(!item.lat||!item.lon)return null
              const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${item.lat}&lon=${item.lon}&accept-language=en`;
              const response = await fetch(url);
              const data = await response.json();
              return data.address.country;
            })
          );
          setVisitedCountries(countries);
        }
      };
      fetchVisitedCountries().catch(console.error);
    },[utente]);
    const countryStyle = (feature) => ({
      fillColor: visitedCountries.includes(feature.properties.admin) ? 'black' : null,
      weight: visitedCountries.includes(feature.properties.admin) ? 1 : 0,
      color: visitedCountries.includes(feature.properties.admin) ? 'black' : null,
      fillOpacity: visitedCountries.includes(feature.properties.admin) ? 0.1 : 0,
    });
    return (
        <MapContainer center={[20, 0]} zoom={1} style={{ height: '100%', width: '100%' }} zoomControl={false} scrollWheelZoom={false} dragging={false} touchZoom={false} doubleClickZoom={false}>
          <TileLayer url="https://www.google.com/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}" attribution='Google Maps'/>
          {visitedCountries.length>0&&(<GeoJSON data={worldGeoJSON} style={countryStyle} />)}
        </MapContainer>
      
    );
  }
  console.log(utente)
  return (
    <div className='utente'>
      <div className='utente-title'>
        <h1>My Profile</h1>
      </div>
      <br />
      {utente?<div className='utente-content'>
        <div className='utente-content-info'>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
            </svg>
            <big>{utente.email}</big>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
            </svg>
            <big>{utente.password}</big>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M5 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4m4-2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5M9 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 9 8m1 2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5"/>
              <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zM1 4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H8.96q.04-.245.04-.5C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 1 1 12z"/>
            </svg>
            <br /><big>{utente.nome}</big>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5m1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0M1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5"/>
            </svg>
            <big>{utente.professione}</big>
          </div>
          <div className='utente-content-info-item'>
            <svg viewBox="0 0 16 16">
              <path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7"/>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
            </svg>
            <big>{new Date(utente.data).getFullYear()}-{new Date(utente.data).getMonth()+1}-{new Date(utente.data).getDate()}</big>
          </div>
        </div>
        <br />
        <div className='utente-content-title'>
          <h2>My Worldwidness</h2>
        </div>
        {(!utente.cronology||utente.cronology.length===0)&&<div className='utente-content-map-black'><big>No data for now!</big></div>}
        <div className='utente-content-map'>
          <ShowVisitedCountries/>
        </div>
        {/*<div className='utente-content-board'>
          <div className='utente-content-board-title'>
            <h3>My Memories</h3>
            {addMemories?<form className='utente-content-board-inputs' onSubmit={(e)=>{
              e.preventDefault()
              sendBoard()
            }}>
              <div className='utente-content-board-inputs-item'>
                <input type="text" id="cityInput" onKeyUp={(e)=>e.target.value.length>2?suggestCities(e.target.value):setSuggest()} placeholder="City..." required/>
                {suggest&&<div className='utente-content-board-inputs-item-suggest'>
                  {suggest?suggest.map((item,n)=>{
                    return(
                      <div className='utente-content-board-inputs-item-suggest-item' key={n} onClick={(e)=>{
                        document.getElementById("cityInput").value=e.target.textContent+";lat:"+item.lat+",lon:"+item.lon
                        setSuggest()
                      }}>
                        <div>{item.display_name}</div>
                      </div>
                    )
                  }):""}
                </div>}
              </div>
              <div className='utente-content-board-inputs-item'>
                <input type="date" id="dateInput" required/>
              </div>
              <div className='utente-content-board-inputs-item'>
                <textarea type="text" rows={4} id="textInput" placeholder='Write your experience...' required/>
              </div>
              <div className='utente-content-board-inputs-item'>
                <button>Send</button>
              </div>
            </form>:<svg viewBox="0 0 16 16" onClick={()=>setAddMemories(e=>!e)}>
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"/>
            </svg>}
          </div>
          <br />
          <div className='utente-content-board-list'>
            {utente.board&&utente.board.length>0?utente.board.map((item,n)=>{
              return(
                <div key={n} className='utente-content-board-list-item'>
                  <div><big>{item.citta.split(";")[0]} {item.dataBoard}</big></div>
                  <div><a href={"https://www.google.com/maps/search/?api=1&query="+item.citta.split(";")[1].split(",")[0].split(":")[1]+','+item.citta.split(";")[1].split(",")[1].split(":")[1]} target='_blank' rel="noreferrer">Directions</a></div>
                  <br />
                  <div style={{color:"#888"}}>{item.text}</div>
                  <br />
                  <small>{new Date(item.data).getFullYear()}-{new Date(item.data).getMonth()+1}-{new Date(item.data).getDate()}/{new Date(item.data).getHours()}:{new Date(item.data).getMinutes()}</small>
                </div>
              )
            }):<img src='https://img.freepik.com/free-vector/hand-drawn-no-data-illustration_23-2150584286.jpg?t=st=1730017467~exp=1730021067~hmac=ccb128c09d6bcc8c7a01a6287b8f98c28825473040cb70acdc1ecc50d4593a30&w=740'/>}
          </div>
        </div>*/}
        <br />
        <br />
        <br />
        <div className='utente-content-button'>
          <div className='utente-content-button-item'>
            <button onClick={()=>{
              document.cookie = "email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              document.cookie = "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              window.location.href="/"
            }} style={{background: "black",color: "white"}}>Logout</button>
          </div>
          <div className='utente-content-button-item'>
            <button onClick={()=>delet()} style={{background: "white",color: "black",border:"1px solid black"}}>Delete Profile</button>
          </div>
        </div>
        <br />
      </div>:<div className='lds'><div className='lds-c'><div className="lds-spinner"></div><div className='fade-text-container'><p className="fade-text">Loading data from servers...</p><p className="fade-text">Translation of content into the selected language...</p><p className="fade-text">Preparing your personalized user experience...</p><p className="fade-text">Please wait thanks...</p></div></div></div>}
    </div>
  )
}

export default Utente